import { HttpAdapterConstructor } from '@itsfadnis/jsonapi-client/dist/http-adapter';
import Model from 'api/model';
import isProduction from './is-production';

type Args = {
  host: string;
  token: string;
  req?: any;
  isAnonymous?: boolean;
};

const getHeaders = (token, cookie) => {
  const headers: any = {
    'x-api-token': `Bearer ${token}`,
  };

  if (cookie) {
    headers.cookie = cookie;
  }

  return headers;
};

export const setup = ({
  host,
  token,
  req,
  isAnonymous = false,
}: Args) => {
  const cookie = req?.headers?.cookie;

  if (!cookie && !isAnonymous) {
    return;
  }

  const newHost =
    isProduction || !process.env.NEXT_PUBLIC_DEV_API_ENDPOINT
      ? host
      : process.env.NEXT_PUBLIC_DEV_API_ENDPOINT;

  const headers = getHeaders(token, cookie);

  Model.configureAdapter({
    fetch,
    namespace: '/api/v2',
    host: newHost,
    headers,
  } as HttpAdapterConstructor);
};
